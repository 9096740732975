<template>
  <LayoutCard :title="$t('hat.screen.deviceEvents')">
    <template #action>
      <a-select
        :options="option"
        v-show="showSelect"
        v-model="params.statisticsPointEnum"
        class="hat-screen-card-select hat-screen-select"
        dropdownClassName="hat-screen-select-dropdown"
        @change="handleChange"
      />
    </template>
    <StackBarChart :chartData="chartData" @click-chart="handleClickChart" />
  </LayoutCard>
</template>
<script>
import { Component } from 'vue-property-decorator';
import StackBarChart from '../charts/bar-chart.vue';
import LayoutCard from './layout-card.vue';
import { ChartComponentsMixin } from './mixins/index';
import { mixins } from 'vue-class-component';
import { deviceEvent } from '@/services/smart-hat/screen';
import { deviceEvent as shareData } from '@/services/smart-hat/screen-share';
import { checkHasShare } from '@/views/hat/screen/utils';
import { createModal } from '@triascloud/x-components';
import ManageModel from '@/views/hat/device/manage-model/index.vue';
import { nowSize } from '@/utils/common';

@Component({
  components: {
    StackBarChart,
    LayoutCard,
  },
})
export default class EquipmentEvent extends mixins(ChartComponentsMixin) {
  async init() {
    try {
      const res = !checkHasShare(this.$route)
        ? await deviceEvent(this.params)
        : await shareData(this.params, {
            shareId: this.$route.params.id,
            password: this.$route.params.password,
          });
      this.chartData = {
        data: res.messageCount.map(({ subType, total }) => ({
          values: total.map((i, idx) => ({
            value: i,
            deviceId: res.deviceIdList[idx],
          })),
          tag: subType,
        })),
        xAxis: res.name,
      };
    } catch (error) {
      throw new Error('Request exception：' + JSON.stringify(error));
    }
  }
  handleClickChart(params) {
    if (
      this.params.statisticsPointEnum === 'PEOPLE' &&
      !checkHasShare(this.$route)
    ) {
      const record = {
        deviceId: params.data.deviceId,
      };
      createModal(
        () => <ManageModel record={record} defaultActiveKey={'event'} />,
        {
          width: nowSize(1280),
          title: this.$t('hat.deviceManagement.manage'),
          maskClosable: false,
          className: this.$style.ManageModel,
        },
      );
    }
  }
}
</script>
<style lang="less" module>
.ManageModel {
  :global {
    .ant-model-body {
      padding: 0;
      margin-bottom: 0;
    }
  }
}
</style>
