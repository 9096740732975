<template>
  <LayoutCard :title="$t('hat.screen.Ranking')">
    <template #action>
      <a-select
        v-show="showSelect"
        v-model="params.statisticsPointEnum"
        :options="option"
        class="hat-screen-card-select hat-screen-select"
        dropdownClassName="hat-screen-select-dropdown"
        @change="handleChange"
      />
    </template>
    <RankChartIndex :chartData="chartData" @click-chart="handleClickChart" />
  </LayoutCard>
</template>
<script>
import { Component, InjectReactive } from 'vue-property-decorator';
import RankChartIndex from '../charts/rank-chart.vue';
import LayoutCard from './layout-card.vue';
import { onlineDurationStatistics } from '@/services/smart-hat/screen';
import { onlineDurationStatistics as shareData } from '@/services/smart-hat/screen-share';
import { ChartComponentsMixin } from './mixins/index';
import { mixins } from 'vue-class-component';
import { checkHasShare } from '@/views/hat/screen/utils';
import { createModal } from '@triascloud/x-components';
import DialogPersonTotalDuration from './dialog/dialog-person-total-duration.vue';
import DialogGroupTotalDuration from './dialog/dialog-group-total-duration.vue';
import { nowSize } from '@/utils/common';

@Component({
  components: {
    RankChartIndex,
    LayoutCard,
  },
})
export default class TotalDuration extends mixins(ChartComponentsMixin) {
  async init() {
    try {
      const res = !checkHasShare(this.$route)
        ? await onlineDurationStatistics(this.params)
        : await shareData(this.params, {
            shareId: this.$route.params.id,
            password: this.$route.params.password,
          });
      this.chartData = res.map(({ name, totalEnterDuration, id }) => ({
        name,
        value: totalEnterDuration,
        id,
      }));
    } catch (error) {
      throw new Error('Request exception：' + JSON.stringify(error));
    }
  }
  @InjectReactive('searchForm') form;
  get hasShare() {
    return checkHasShare(this.$route);
  }
  get shareParams() {
    return {
      shareId: this.$route.params.id,
      password: this.$route.params.password,
    };
  }
  handleClickChart(params) {
    let Component, title, dayTitle, width;
    if (this.form.searchDayEnum === 'CURRENT_DAY') {
      dayTitle = '今天';
    } else if (this.form.searchDayEnum === 'SEVEN_DAY') {
      dayTitle = '近7天';
    } else if (this.form.searchDayEnum === 'THIRTY_DAY') {
      dayTitle = '近30天';
    }
    title = `${params.name}${dayTitle}在场总时长明细`;
    if (this.params.statisticsPointEnum === 'PEOPLE') {
      Component = DialogPersonTotalDuration;
      width = nowSize(1100);
    } else {
      Component = DialogGroupTotalDuration;
      width = nowSize(800);
    }
    createModal(
      () => (
        <Component
          dayEnum={this.form.searchDayEnum}
          id={params.data.id}
          hasShare={this.hasShare}
          shareParams={this.shareParams}
        />
      ),
      {
        title,
        width,
      },
    );
  }
}
</script>
<style lang="less" module></style>
