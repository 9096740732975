<template>
  <div :class="$style.card">
    <slot name="headerContent">
      <div v-if="showHeader" :class="$style.header">
        <div :class="$style.headerContent">
          <slot name="header">
            <div :class="$style.title">
              {{ title }}
            </div>
            <slot name="action" />
          </slot>
        </div>
      </div>
    </slot>
    <div :class="$style.content">
      <slot />
    </div>
  </div>
</template>
<script>
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component()
export default class LayoutCard extends Vue {
  @Prop({ type: [String, Array] }) title;
  @Prop({ type: Boolean, default: true }) showHeader;
}
</script>
<style module lang="less">
.card {
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
  overflow: hidden;
  .header {
    height: 0.4rem;
    display: flex;
    align-items: center;
    margin-bottom: 0.08rem;
    background: var(--card-header-background);
    background-size: 100% 100%;
    .headerContent {
      flex: 1;
      height: 100%;
      display: flex;
      align-items: center;
      padding: 0 0.33rem 0 0.42rem;
      .title {
        flex: 1;
        font-size: 0.15rem;
        // color: rgba(255, 255, 255, 0.9);
        color: var(--screen-font);
        font-weight: 600;
        letter-spacing: 2px;
        // text-shadow: 0 0 7px #1f69c8, 0 0 10px #7db6ff;
        display: flex;
        align-items: center;
        height: 100%;
      }
    }
  }
  .content {
    // display: flex;
    flex-direction: column;
    flex: 1;
    overflow: hidden;
  }
}
</style>
