const Theme = {
  white: {
    mapLink: '',
    chart: {
      fontColor: '#333333',
      dashedColor: '#DEDEDE',
      lineColor: '#DEDEDE',
      pageIconInactiveColor: '#DDDDDD',
      borderColor: 'rgba(255, 255, 255, 0)',
    },
  },
  dark: {
    mapLink: 'amap://styles/9a6b29f013ae9965ddf7fe1cee0c4cfb',
    chart: {
      fontColor: 'rgba(251, 253, 255, 0.8)',
      dashedColor: 'rgba(255, 255, 255, 0.20)',
      lineColor: 'rgba(56, 88, 134, 1)',
      pageIconInactiveColor: '#2f4554',
      borderColor: '#0A243D',
    },
  },
};

export default Theme;
