<template>
  <a-table
    :columns="columns"
    :data-source="tableData"
    :pagination="pagination"
    :row-key="record => record.deviceId"
    :loading="loading"
    @change="handlePagination"
  >
  </a-table>
</template>
<script>
import { Component, Vue, Prop } from 'vue-property-decorator';
import { clipboardWrite } from '@triascloud/utils';
import { getDeviceList } from '@/services/smart-hat/device-management';
import { getOnlineDevice } from '@/services/smart-hat/screen-share';
import { createModal } from '@triascloud/x-components';
import ManageModel from '@/views/hat/device/manage-model/index.vue';
import { getBackendAuthForOne } from '@/services/oss/oss';
@Component()
export default class DialogOnline extends Vue {
  pagination = {
    current: 1,
    pageSize: 10,
    total: 0,
    showQuickJumper: true,
    showSizeChanger: true,
    pageSizeOptions: ['10', '20', '50', '100'],
    showTotal: total => this.$t('todo.flow.total', { total }),
  };
  loading = false;
  tableData = [];
  copyPhone(val) {
    clipboardWrite(val).then(() => {
      this.$message.success('复制成功');
    });
  }
  get columns() {
    const list = [
      {
        align: 'left',
        title: '设备号',
        dataIndex: 'deviceName',
        ellipsis: true,
      },
      {
        align: 'left',
        title: '当前佩戴人员',
        dataIndex: 'userName',
        ellipsis: true,
        customRender: (text, record) => (
          <div>
            <a-tooltip placement="top">
              <template slot="title">
                <div>
                  <div
                    onClick={() => {
                      this.copyPhone(record.mobilePhone);
                    }}
                  >
                    {this.$t('enterpriseManagement.camera.tableColumn.phone')}：
                    {record.mobilePhone}
                  </div>
                </div>
              </template>
              {record.userAvatar ? (
                <x-oss-image
                  service={
                    this.hasShare
                      ? {
                          getAuth: getBackendAuthForOne,
                        }
                      : ''
                  }
                  class={this.$style.avatar}
                  ossPath={record.userAvatar}
                />
              ) : null}
              <span>{record.userName}</span>
            </a-tooltip>
          </div>
        ),
      },
      {
        title: '所属项目',
        dataIndex: 'projectNames',
        ellipsis: true,
        customRender: (_, record) => (
          <div>
            <a-tooltip placement="top">
              <template slot="title">
                <div>
                  {record.projectNames && record.projectNames.length > 0 ? (
                    <div>{record.projectNames.join('、')}</div>
                  ) : null}
                </div>
              </template>
              <span>
                {record.projectNames && record.projectNames.length > 0
                  ? record.projectNames[0]
                  : '-'}
              </span>
            </a-tooltip>
          </div>
        ),
      },
      {
        title: '所属编组',
        dataIndex: 'groupName',
        ellipsis: true,
        customRender: text => (text ? text : '-'),
      },
      {
        title: '角色',
        dataIndex: 'roleCOList',
        ellipsis: true,
        customRender: (_, record) => (
          <div>
            <a-tooltip placement="top">
              <template slot="title">
                <div>
                  {record.roleCOList && record.roleCOList.length > 0 ? (
                    <div>{this.roleNames(record.roleCOList)}</div>
                  ) : null}
                </div>
              </template>
              <span>
                {record.roleCOList && record.roleCOList.length > 0
                  ? record.roleCOList[0].roleName
                  : '-'}
              </span>
            </a-tooltip>
          </div>
        ),
      },
      {
        align: 'left',
        title: '最近上线时间',
        dataIndex: 'lastOnlineTime',
        ellipsis: true,
        customRender: text =>
          text ? this.$moment(text).format('YYYY-MM-DD HH:mm:ss') : '-',
      },
    ];
    if (!this.hasShare) {
      list.push({
        title: this.$t('enterpriseManagement.camera.tableColumn.operation'),
        key: 'operation',
        ellipsis: true,
        customRender: (_, record) => (
          <span
            style={{
              color: 'var(--font-active)',
              cursor: 'pointer',
            }}
            onClick={() => this.handleDeviceItem(record)}
          >
            设备详情
          </span>
        ),
      });
    }
    return list;
  }
  @Prop({ type: Object }) form;
  @Prop({ type: Object }) shareParams;
  @Prop({ type: Boolean }) hasShare;
  async getData() {
    this.loading = true;
    try {
      const params = {
        status: 'ONLINE',
        searchDayEnum: this.form.searchDayEnum,
        projectId: this.form.projectIds,
        groupIdList: this.form.groupIdList.toString(),
        current: this.pagination.current,
        size: this.pagination.pageSize,
      };
      const { records, total } = this.hasShare
        ? await getOnlineDevice(params, this.shareParams)
        : await getDeviceList(params);
      this.tableData = records;
      this.pagination.total = total;
      // eslint-disable-next-line no-empty
    } catch (error) {
    } finally {
      this.loading = false;
    }
  }
  handleDeviceItem(item) {
    createModal(
      () => <ManageModel record={item} defaultActiveKey={'event'} />,
      {
        width: 1280,
        title: this.$t('hat.deviceManagement.manage'),
        maskClosable: false,
        className: this.$style.ManageModel,
      },
    );
  }
  roleNames(list) {
    let str = list.reduce((v, a) => v + a.roleName + '、', '');
    if (str.slice(-1) === '、') {
      str = str.slice(0, -1);
    }
    return str;
  }
  handlePagination(data) {
    this.pagination = data;
    this.getData();
  }
  mounted() {
    this.getData();
  }
}
</script>
<style lang="less" module>
.avatar {
  height: 0.24rem;
  width: 0.24rem;
  border-radius: 50%;
  object-fit: cover;
  transform: translateY(28%);
  margin-right: 0.1rem;
}
.ManageModel {
  :global {
    .ant-model-body {
      padding: 0;
      margin-bottom: 0;
    }
  }
}
</style>
