<template>
  <div ref="barChartRef" style="width: 100%; height: 100%;"></div>
</template>
<script>
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import * as echarts from 'echarts';
import moment from 'moment';
import { nowSize } from '@/utils/common';
import { Emitter } from '@/utils';
import { omittedText } from '@/views/hat/utils';
import { chartColors } from '../utils';
import Theme from '@/views/hat/screen/config/theme';

const skin = localStorage.getItem('HAT_SCREEN_SKIN');

/**@name 堆叠柱状图 */
@Component()
export default class StackBarChart extends Vue {
  @Prop({
    type: Object,
    default: () => ({}),
  })
  chartData;

  @Watch('chartData', { deep: true, immediate: true })
  async chartDataChange(val) {
    if (val) {
      await this.$nextTick();
      this.initChart();
    } else {
      this.chartInstance?.dispose();
      this.chartInstance = null;
    }
  }

  /**@type {echarts.EChartsCoreOption} */
  get options() {
    if (!this.chartData) return {};
    return {
      color: chartColors,
      tooltip: {
        appendToBody: true,
        trigger: 'axis',
        // position: (pos, params, el, elRect, size) => {
        //   const obj = { top: 0 };
        //   obj[['left', 'right'][+(pos[0] < size.viewSize[0] / 2)]] = 30;
        //   return obj;
        // },
        borderWidth: 0,
        textStyle: {
          fontSize: nowSize(12),
          color: '#fff',
        },
        backgroundColor: 'rgba(9, 77, 143, 0.8)',
        formatter: params => {
          const name = `<div style="font-size:11.7px;color:#fff;font-weight:400;line-height:1;">${params[0].name}</div>`;
          const valusTetx = params
            .filter(item => item.value > 0)
            .map(item => {
              return `<div style="margin: 10px 0 0;line-height:1;"><div style="margin: 0px 0 0;line-height:1;"><span style="display:inline-block;margin-right:4px;border-radius:10px;width:10px;height:10px;background-color:${item.color};"></span><span style="font-size:11.7px;color:#fff;font-weight:400;margin-left:2px">${item.seriesName}</span><span style="float:right;margin-left:20px;font-size:11.7px;color:#fff;font-weight:900">${item.value}</span><div style="clear:both"></div></div><div style="clear:both"></div></div>`;
            })
            .join('\n');

          return name + valusTetx;
        },
      },
      legend: {
        bottom: 0,
        type: 'scroll',
        itemHeight: nowSize(3),
        itemWidth: nowSize(13),
        textStyle: {
          color: Theme[skin].chart.fontColor,
          fontSize: nowSize(12),
          lineHeight: nowSize(18),
        },
        pageIconInactiveColor: Theme[skin].chart.pageIconInactiveColor,
        pageIconColor: Theme[skin].chart.fontColor,
        pageTextStyle: {
          color: Theme[skin].chart.fontColor,
        },
      },
      grid: {
        left: '3%',
        right: '4%',
        bottom: nowSize(30),
        top: '5%',
        containLabel: true,
      },
      dataZoom: [
        {
          type: 'inside',
          startValue: this.chartData.xAxis[0] ?? 0,
          endValue:
            this.chartData.xAxis[4] ??
            this.chartData.xAxis[this.chartData.xAxis.legend - 1],
        },
      ],
      yAxis: {
        type: 'value',
        axisLabel: {
          color: Theme[skin].chart.fontColor,
          fontSize: nowSize(12),
        },
        axisLine: {
          lineStyle: {
            color: Theme[skin].chart.dashedColor,
          },
        },
        splitLine: {
          lineStyle: {
            type: 'dashed',
            color: Theme[skin].chart.dashedColor,
          },
        },
      },
      xAxis: {
        type: 'category',
        data: this.chartData.xAxis.map(item =>
          moment(item).isValid() ? moment(item).format('YYYY-MM-DD') : item,
        ),
        axisLabel: {
          color: Theme[skin].chart.fontColor,
          fontSize: nowSize(12),
          formatter: value => {
            return omittedText(value, 4);
          },
        },
        axisLine: {
          lineStyle: {
            color: Theme[skin].chart.lineColor,
          },
        },
      },
      series: this.chartData.data.map(item => {
        return {
          barWidth: nowSize(15),
          name: item.tag,
          type: 'bar',
          stack: 'total',
          label: {
            // show: true,
          },
          // itemStyle: {
          //   color: index > 9 ? this.handleColors() : '',
          // },
          // emphasis: {
          //   focus: 'series',
          // },
          data: item.values,
        };
      }),
    };
  }

  //随机生成颜色
  handleColors() {
    let color = '';
    let r = Math.floor(Math.random() * 256);
    let g = Math.floor(Math.random() * 256);
    let b = Math.floor(Math.random() * 256);
    color = `rgb(${r},${g},${b})`;
    return color;
  }

  /**@type {echarts.ECharts} */
  chartInstance = null;
  initChart() {
    if (!this.chartInstance) {
      this.chartInstance = echarts.init(this.$refs.barChartRef);
    }

    this.chartInstance.setOption(this.options);
    this.chartInstance.off('click');
    this.chartInstance.on('click', params => {
      this.chartInstance.dispatchAction({
        type: 'hideTip',
      });
      this.$emit('click-chart', params);
    });
  }

  resize() {
    this.chartInstance.resize({
      width: 'auto',
      height: 'auto',
    });
  }

  async mounted() {
    this.initChart();
    Emitter.$on('bodyResize', this.resize);
  }
  beforeDestroy() {
    Emitter.$off('bodyResize', this.resize);
  }
}
</script>
