<template>
  <div
    ref="lineChartRef"
    style="width: 100%;height: 100%;overflow: hidden;"
  ></div>
</template>
<script>
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import * as echarts from 'echarts';
import moment from 'moment';
import { nowSize } from '@/utils/common';
import { Emitter } from '@/utils';
import { omittedText } from '@/views/hat/utils';
import { chartColors } from '../utils';
import Theme from '@/views/hat/screen/config/theme';

const skin = localStorage.getItem('HAT_SCREEN_SKIN');

@Component()
export default class LineChartIndex extends Vue {
  @Prop({
    type: Object,
    default: () => ({}),
  })
  chartData;

  @Watch('chartData', { deep: true, immediate: true })
  async chartDataChange(val) {
    if (val) {
      await this.$nextTick();
      this.initChart();
    } else {
      this.chartInstance?.dispose();
      this.chartInstance = null;
    }
  }

  /**@type {echarts.EChartsCoreOption} */
  get options() {
    if (!this.chartData) return {};
    return {
      color: chartColors,
      tooltip: {
        appendToBody: true,
        trigger: 'axis',
        // position: (pos, params, el, elRect, size) => {
        //   const obj = { top: 0 };
        //   obj[['left', 'right'][+(pos[0] < size.viewSize[0] / 2)]] = 30;
        //   return obj;
        // },
        borderWidth: 0,
        textStyle: {
          fontSize: nowSize(12),
          color: '#fff',
        },
        backgroundColor: 'rgba(9, 77, 143, 0.8)',
      },
      legend: {
        bottom: 0,
        type: 'scroll',
        itemHeight: nowSize(3),
        itemWidth: nowSize(13),
        textStyle: {
          color: Theme[skin].chart.fontColor,
          fontSize: nowSize(12),
        },
      },
      grid: {
        left: '3%',
        right: '4%',
        bottom: nowSize(30),
        top: '5%',
        containLabel: true,
      },
      dataZoom: [
        {
          type: 'inside',
          startValue: this.chartData.xAxis[0] ?? 0,
          endValue:
            this.chartData.xAxis[4] ??
            this.chartData.xAxis[this.chartData.xAxis.length],
        },
      ],
      yAxis: {
        type: 'value',
        axisLabel: {
          color: Theme[skin].chart.fontColor,
          fontSize: nowSize(12),
        },
        axisLine: {
          lineStyle: {
            color: Theme[skin].chart.dashedColor,
          },
        },
        splitLine: {
          lineStyle: {
            type: 'dashed',
            color: Theme[skin].chart.dashedColor,
          },
        },
      },
      xAxis: {
        type: 'category',
        data: this.chartData.xAxis.map(item =>
          moment(item).isValid() ? moment(item).format('YYYY-MM-DD') : item,
        ),
        axisLabel: {
          color: Theme[skin].chart.fontColor,
          fontSize: nowSize(12),
          formatter: value => {
            return omittedText(value, 6);
          },
        },
        axisLine: {
          lineStyle: {
            color: Theme[skin].chart.lineColor,
          },
        },
      },
      series: this.chartData.data.map(item => {
        return {
          name: item.tag,
          type: 'line',
          // stack: 'total',
          label: {
            show: false,
          },
          emphasis: {
            focus: 'series',
          },
          data: item.values,
        };
      }),
    };
  }

  /**@type {echarts.ECharts} */
  chartInstance = null;
  initChart() {
    if (!this.chartInstance) {
      this.chartInstance = echarts.init(this.$refs.lineChartRef);
    }

    this.chartInstance.setOption(this.options);
    this.chartInstance.off('click');
    this.chartInstance.on('click', params => {
      this.$emit('click-chart', params);
    });
  }

  resize() {
    this.chartInstance.resize({
      width: 'auto',
      height: 'auto',
    });
  }

  async mounted() {
    this.initChart();
    Emitter.$on('bodyResize', this.resize);
  }
  beforeDestroy() {
    Emitter.$off('bodyResize', this.resize);
  }
}
</script>
