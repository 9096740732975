<template>
  <div :class="$style.header">
    <div :class="$style.left"></div>
    <div :class="$style.center">
      {{ $t('hat.screen.title') }}
    </div>
    <div :class="$style.right">
      <div :class="$style.time">
        <span>{{ date.date }}</span>
        <span>{{ date.week }}</span>
        <span>{{ time }}</span>
      </div>
      <div
        :class="$style.icon"
        @click.stop="handleSetting()"
        v-if="$p.action('IOT-SCREEN-SHARE') && screenAuth"
      >
        <a-icon type="setting" />
      </div>
      <div :class="$style.icon">
        <a-popover
          trigger="click"
          placement="bottom"
          :overlayClassName="$style.skinBox"
        >
          <div slot="content" :class="$style.skinList">
            <div
              :class="$style.skinItem"
              v-for="(item, index) in themes"
              :key="index"
              @click.stop="handleChangeTheme(item, index)"
            >
              <a-icon
                type="check"
                :class="$style.check"
                v-if="index === selectedIndex"
              />
              <div
                :class="$style.itemBg"
                :style="{ backgroundColor: item.color }"
              ></div>
            </div>
          </div>
          <a-icon type="skin" />
        </a-popover>
      </div>
      <div
        :class="$style.icon"
        @click.stop="handleShare()"
        v-if="$p.action('IOT-SCREEN-SHARE')"
      >
        <a-icon type="share-alt" />
      </div>
      <div :class="$style.icon" @click="handleFullScreen">
        <a-icon type="fullscreen" v-if="!isFullScreen" />
        <a-icon type="fullscreen-exit" v-else />
      </div>
    </div>
  </div>
</template>
<script>
import { Component, Vue, InjectReactive } from 'vue-property-decorator';
import moment from 'moment';
import { createFormModal, createModal } from '@triascloud/x-components';
import ShareForm from './share/share-form.vue';
import ShareBox from './share/share-box.vue';
import ScreenSetting from './dialog/screen-setting.vue';
import { fullScreen } from '@/utils/common.js';
import { getAuth } from '@/services/smart-hat/screen';
import { checkHasShare } from '@/views/hat/screen/utils';

@Component()
export default class HatScreenHeader extends Vue {
  get date() {
    const now = moment(this.now);
    return {
      week: {
        1: this.$t('hat.screen.Mon'),
        2: this.$t('hat.screen.Tue'),
        3: this.$t('hat.screen.Wed'),
        4: this.$t('hat.screen.Thu'),
        5: this.$t('hat.screen.Fri'),
        6: this.$t('hat.screen.Sat'),
        7: this.$t('hat.screen.Sun'),
      }[now.format('e')],
      date: now.format('YYYY.MM.DD'),
    };
  }
  get time() {
    return moment(this.now).format('HH:mm:ss');
  }
  interval = 0;
  now = Date.now();
  beforeDestroy() {
    clearInterval(this.interval);
  }
  created() {
    this.interval = setInterval(() => {
      this.now = Date.now();
    }, 1000);
    this.selectedIndex = this.themes.findIndex(
      v => v.theme === localStorage.getItem('HAT_SCREEN_SKIN'),
    );
    if (!checkHasShare(this.$route)) {
      this.getScreenAuth();
    }
  }
  @InjectReactive('formDataLabel') formLabel;
  async handleShare() {
    const data = {
      projectId: '123',
      projectName: '246',
    };
    const result = await createFormModal(
      () => <ShareForm data={data} formLabel={this.formLabel} />,
      {
        width: this.$size(628),
        title: this.$t('hat.screen.share'),
        okText: this.$t('hat.screen.GenerateLink'),
        maskClosable: false,
      },
    );
    const modal = createModal(
      () => <ShareBox shareInfo={result} onClose={() => modal.handleClose()} />,
      {
        title: this.$t('hat.screen.share'),
        width: this.$size(420),
        maskClosable: false,
      },
    );
  }
  isFullScreen = false;
  handleFullScreen() {
    this.isFullScreen = !this.isFullScreen;
    fullScreen();
  }
  async handleSetting() {
    const result = await createFormModal(() => <ScreenSetting />, {
      width: 500,
      title: '大屏设置',
      maskClosable: false,
    });
    if (result) {
      // await this.getTableList();
    }
  }
  themes = [
    {
      color: '#072640',
      theme: 'dark',
    },
    {
      color: '#ddd',
      theme: 'white',
    },
  ];
  selectedIndex = 0;
  handleChangeTheme(item, index) {
    this.selectedIndex = index;
    this.$emit('handleChangeTheme', item.theme);
  }
  screenAuth = false;
  async getScreenAuth() {
    const data = await getAuth();
    this.screenAuth = data;
  }
}
</script>
<style lang="less" module>
.header {
  height: 0.62rem;
  width: 100%;
  display: flex;
  justify-content: space-between;
  position: absolute;
  z-index: 99;
  top: 0;
  background: var(--header-background);
  background-size: 100% 100%;
  color: #ffffff;
  .left,
  .center,
  .right {
    width: 30%;
    display: flex;
  }
  .left {
    align-items: center;
    // margin-top: 0.08rem;
  }
  .center {
    justify-content: center;
    // align-items: center;
    line-height: 0.5rem;
    letter-spacing: 3px;
    font-size: 0.24rem;
    font-weight: 600;
  }
  .right {
    justify-content: flex-end;
    align-items: center;
    margin-right: 0.24rem;
    // margin-top: 0.08rem;
    .icon {
      margin-left: 0.16rem;
      font-size: 0.19rem;
      display: inline-block;
      text-align: center;
      cursor: pointer;
    }
    .time {
      margin-right: 0.14rem;
      span {
        font-size: 0.16rem;
        margin-right: 0.08rem;
      }
    }
  }
}
.header::before {
  content: '';
  width: 100%;
  height: 115%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
  background: linear-gradient(
    180deg,
    rgba(3, 13, 20, 0.6) 0%,
    rgba(9, 44, 71, 0) 100%
  );
  // filter: blur(0.05rem);
}
.skinBox {
  top: 52px !important;
  .skinList {
    max-width: 2.2rem;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 0.1rem;
    .skinItem {
      text-align: center;
      position: relative;
      margin-bottom: 0.08rem;
      .itemBg {
        height: 0.42rem;
        width: 0.42rem;
        border-radius: 0.04rem;
        cursor: pointer;
      }
      .check {
        position: absolute;
        font-size: 0.14rem;
        color: #fff;
        left: 0.04rem;
        top: 0.04rem;
      }
    }
  }
  :global {
    .ant-popover-inner {
      background-color: #fff;
    }
    .ant-popover-arrow {
      border-color: #fff !important;
    }
    .ant-popover-inner-content {
      background-color: #fff;
      border-radius: 0.06rem;
      padding: 0.12rem 0.12rem 0.04rem;
    }
  }
}
</style>
