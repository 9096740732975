<template>
  <div :class="$style.footer">
    <div :class="$style.typeList">
      <div
        :class="[$style.subType, !item.selected ? $style.disabled : '']"
        v-for="item in typeList"
        :key="item.name"
        @click="handleSelectType(item)"
      >
        <div
          :class="[$style.typeBg, !item.selected ? $style.disabled : '']"
          :style="{
            background: `radial-gradient(circle,${item.color},rgba(218, 32, 49, 0))`,
          }"
        ></div>
        <i
          v-html="item.icon"
          :style="{ width: item.size }"
          v-if="item.isCustomize"
        ></i>
        <a-icon :class="$style.icon" :type="item.icon" theme="filled" v-else />
        <span>{{ item.name }}</span>
        <span :class="$style.count">{{ item.number }}</span>
        <span :class="$style.font">{{ $t('hat.screen.devices') }}</span>
      </div>
    </div>
  </div>
</template>
<script>
import { Component, Vue } from 'vue-property-decorator';
import { getTypeStatic } from '@/services/smart-hat/screen';
import { checkHasShare } from '@/views/hat/screen/utils';
import { getTypeStatic as shareData } from '@/services/smart-hat/screen-share';
import { Emitter } from '@/utils';
import { DEVICE_TYPE } from './map/index.vue';
import OnlineIcon from '!!svg-inline-loader!@/assets/images/smart-hat/screen/online.svg';
import NotificationIcon from '!!svg-inline-loader!@/assets/images/smart-hat/screen/notification.svg';
import NormalIcon from '!!svg-inline-loader!@/assets/images/smart-hat/screen/normal.svg';

@Component()
export default class HatScreenFooter extends Vue {
  typeList = [];
  async getTypeStatic(params) {
    const param = {
      groupIdList: params.groupIdList.toString(),
      projectIds: params.projectIds.toString(),
      searchDayEnum: params.searchDayEnum,
    };
    const data = !checkHasShare(this.$route)
      ? await getTypeStatic(param)
      : await shareData(param, {
          shareId: this.$route.params.id,
          password: this.$route.params.password,
        });
    if (this.typeList.length > 0) {
      this.typeList[0].number = data.onLine;
      this.typeList[1].number = data.alarm;
      this.typeList[2].number = data.remind;
      this.typeList[3].number = data.notice;
      this.typeList[4].number = data.normal;
      if (params.reset) {
        this.typeList[0].selected = false;
        this.typeList[1].selected = true;
        this.typeList[2].selected = true;
        this.typeList[3].selected = true;
        this.typeList[4].selected = true;
      }
    } else {
      this.typeList = [
        {
          name: this.$t('hat.managementModal.personInfo.online'),
          number: data.onLine,
          color: 'rgba(9, 255, 0, 1)',
          size: '0.22rem',
          icon: OnlineIcon,
          selected: false,
          isCustomize: true,
          type: 'ONLINE',
        },
        {
          name: this.$t('hat.screen.alarm'),
          number: data.alarm,
          icon: 'warning',
          color: 'rgba(218, 32, 49, 1)',
          selected: true,
          isCustomize: false,
          type: DEVICE_TYPE.ALARM,
        },
        {
          name: this.$t('hat.screen.remind'),
          number: data.remind,
          icon: 'bell',
          color: 'rgba(255, 221, 0, 1)',
          selected: true,
          isCustomize: false,
          type: DEVICE_TYPE.REMIND,
        },
        {
          name: this.$t('hat.screen.notice'),
          number: data.notice,
          icon: NotificationIcon,
          color: 'rgba(0, 111, 185, 1)',
          size: '0.28rem',
          selected: true,
          isCustomize: true,
          type: DEVICE_TYPE.NOTICE,
        },
        {
          name: this.$t('hat.screen.normal'),
          number: data.normal,
          icon: NormalIcon,
          color: 'rgba(0, 137, 82, 1)',
          size: '0.25rem',
          isCustomize: true,
          selected: true,
          type: DEVICE_TYPE.NORMAL,
        },
      ];
    }
  }
  handleSelectType(item) {
    item.selected = !item.selected;
    Emitter.$emit('device-type', item);
  }
}
</script>
<style lang="less" module>
.footer {
  height: 0.69rem;
  width: 100%;
  position: absolute;
  z-index: 10;
  left: 50%;
  transform: translateX(-50%);
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  .typeList {
    height: 100%;
    width: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .subType {
      width: 1.73rem;
      height: 0.4rem;
      display: flex;
      align-items: center;
      justify-content: center;
      color: var(--subType-font);
      font-size: 0.16rem;
      position: relative;
      &.disabled {
        color: var(--subType-font__disabled);
      }
      .font {
        font-size: 0.16rem;
      }
      .typeBg {
        width: 1.73rem;
        height: 0.35rem;
        border-radius: 50%;
        filter: blur(0.08rem);
        position: absolute;
        opacity: var(--subType-opacity);
        z-index: var(--subType-zIndex);
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        &.disabled {
          background: none !important;
        }
      }
      .icon {
        font-size: 0.24rem;
      }
      & > span:first-of-type {
        margin: 0 0.12rem;
      }
      & .count {
        font-size: 0.22rem;
      }
    }
  }
}
.footer::before {
  content: '';
  width: 100%;
  height: 110%;
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: -1;
  background: linear-gradient(
    0,
    rgba(3, 13, 20, 0.6) 0%,
    rgba(9, 44, 71, 0) 100%
  );
  opacity: 0.8;
  filter: blur(0.08rem);
}
</style>
