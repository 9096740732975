<template>
  <a-form-model layout="vertical" :model="form" ref="form">
    <a-form-model-item label="默认区域" prop="scope">
      <a-radio-group name="radioGroup" v-model="form.scope">
        <a-radio value="ALL">
          全国
        </a-radio>
        <a-radio value="GROUP">
          指定编组
        </a-radio>
        <a-radio value="IP">
          访问者IP地址
        </a-radio>
      </a-radio-group>
    </a-form-model-item>
    <a-select
      class="hat-screen-select"
      dropdownClassName="hat-screen-select-dropdown"
      option-filter-prop="children"
      :filter-option="true"
      v-model="form.groupId"
      :options="groupList"
      placeholder="请选择编组"
      style="width: 2rem;"
      v-if="form.scope === 'GROUP'"
    />
    <a-form-model-item label="自动更新" prop="autoUpdate">
      <a-radio-group name="radioGroup" v-model="form.autoUpdate">
        <a-radio :value="true">
          开启
        </a-radio>
        <a-radio :value="false"> 关闭</a-radio>
      </a-radio-group>
    </a-form-model-item>
  </a-form-model>
</template>

<script>
import { Component, Vue } from 'vue-property-decorator';
import {
  putScreenSetting,
  getScreenSetting,
} from '@/services/smart-hat/screen';
import { getGroupList } from '@/services/smart-hat/screen';

@Component()
export default class ScreenSetting extends Vue {
  form = {
    scope: 'ALL',
    autoUpdate: true,
    groupId: undefined,
    screenSettingId: '',
  };
  async mounted() {
    await this.getGroupList();
    this.getScreenSetting();
  }
  async getScreenSetting() {
    this.form = await getScreenSetting();
  }
  groupList = [];
  async getGroupList(projectIds) {
    try {
      const groupList = await getGroupList({
        projectIds,
      });
      this.groupList = groupList.map(item => ({
        label: item.deviceGroupName,
        value: item.pkId,
      }));
    } catch (e) {
      return false;
    }
  }
  async getValue() {
    return new Promise((resolve, reject) => {
      this.$refs.form.validate(async valid => {
        if (valid) {
          try {
            await putScreenSetting(this.form);
            this.$message.success(
              this.$t('hat.deviceManagement.editModal.modified'),
            );
            resolve(true);
          } catch {
            reject(false);
          }
        } else {
          reject(false);
        }
      });
    });
  }
}
</script>

<style lang="less" module></style>
