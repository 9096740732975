<template>
  <LayoutCard :title="$t('hat.screen.Distribution')">
    <template #action>
      <a-select
        v-show="showSelect"
        v-model="params.statisticsPointEnum"
        :options="option"
        class="hat-screen-card-select hat-screen-select"
        dropdownClassName="hat-screen-select-dropdown"
        @change="handleChange"
      />
    </template>
    <PieChartIndex :chartData="chartData" />
  </LayoutCard>
</template>
<script>
import { Component } from 'vue-property-decorator';
import PieChartIndex from '../charts/pie-chart.vue';
import LayoutCard from './layout-card.vue';
import { durationStatistics } from '@/services/smart-hat/screen';
import { durationStatistics as shareData } from '@/services/smart-hat/screen-share';
import { ChartComponentsMixin } from './mixins/index';
import { mixins } from 'vue-class-component';
import { checkHasShare } from '@/views/hat/screen/utils';
import { DurationRatioEnum } from '../enum';

@Component({
  components: {
    PieChartIndex,
    LayoutCard,
  },
})
export default class TimeProportion extends mixins(ChartComponentsMixin) {
  async init() {
    try {
      const res = !checkHasShare(this.$route)
        ? await durationStatistics(this.params)
        : await shareData(this.params, {
            shareId: this.$route.params.id,
            password: this.$route.params.password,
          });
      this.chartData =
        res.map(({ count, tagName }) => ({
          value: count,
          name: DurationRatioEnum[tagName],
        })) ?? [];
    } catch (error) {
      throw new Error('Request exception：' + JSON.stringify(error));
    }
  }
}
</script>
<style lang="less" module></style>
