<template>
  <a-table
    :columns="columns"
    :data-source="tableData"
    :pagination="pagination"
    :row-key="record => record.deviceId"
    :loading="loading"
    @change="handlePagination"
  >
  </a-table>
</template>
<script>
import { Component, Vue, Prop } from 'vue-property-decorator';
import { realPresenceDetail } from '@/services/smart-hat/activity-record';
import { shareRealPresenceDetail } from '@/services/smart-hat/screen-share';
import { clipboardWrite } from '@triascloud/utils';
import { getBackendAuthForOne } from '@/services/oss/oss';

@Component()
export default class DialogPresence extends Vue {
  pagination = {
    current: 1,
    pageSize: 10,
    total: 0,
    showQuickJumper: true,
    showSizeChanger: true,
    pageSizeOptions: ['10', '20', '50', '100'],
    showTotal: total => this.$t('todo.flow.total', { total }),
  };
  loading = false;
  tableData = [];
  copyPhone(val) {
    clipboardWrite(val).then(() => {
      this.$message.success('复制成功');
    });
  }
  get columns() {
    const list = [
      {
        align: 'left',
        title: '姓名',
        dataIndex: 'userName',
        ellipsis: true,
        customRender: (text, record) => (
          <div>
            <a-tooltip placement="top">
              <template slot="title">
                <div>
                  <div
                    onClick={() => {
                      this.copyPhone(record.mobilePhone);
                    }}
                  >
                    {this.$t('enterpriseManagement.camera.tableColumn.phone')}：
                    {record.mobilePhone}
                  </div>
                </div>
              </template>
              {record.avatar ? (
                <x-oss-image
                  service={
                    this.hasShare
                      ? {
                          getAuth: getBackendAuthForOne,
                        }
                      : ''
                  }
                  class={this.$style.avatar}
                  ossPath={record.avatar}
                />
              ) : null}
              <span>{record.userName}</span>
            </a-tooltip>
          </div>
        ),
      },
      {
        title: '所属项目',
        dataIndex: 'projectNameList',
        ellipsis: true,
        customRender: (_, record) => (
          <div>
            <a-tooltip placement="top">
              <template slot="title">
                <div>
                  {record.projectNameList &&
                  record.projectNameList.length > 0 ? (
                    <div>{record.projectNameList.join('、')}</div>
                  ) : null}
                </div>
              </template>
              <span>
                {record.projectNameList && record.projectNameList.length > 0
                  ? record.projectNameList[0]
                  : '-'}
              </span>
            </a-tooltip>
          </div>
        ),
      },
      {
        title: '所属编组',
        dataIndex: 'groupNameList',
        ellipsis: true,
        customRender: (_, record) => (
          <div>
            <a-tooltip placement="top">
              <template slot="title">
                <div>
                  {record.groupNameList && record.groupNameList.length > 0 ? (
                    <div>{record.groupNameList.join('、')}</div>
                  ) : null}
                </div>
              </template>
              <span>
                {record.groupNameList && record.groupNameList.length > 0
                  ? record.groupNameList[0]
                  : '-'}
              </span>
            </a-tooltip>
          </div>
        ),
      },
      {
        title: '角色',
        dataIndex: 'roleList',
        ellipsis: true,
        customRender: (_, record) => (
          <div>
            <a-tooltip placement="top">
              <template slot="title">
                <div>
                  {record.roleList && record.roleList.length > 0 ? (
                    <div>
                      {this.splitFieldForList(record.roleList, 'roleName')}
                    </div>
                  ) : null}
                </div>
              </template>
              <span>
                {record.roleList && record.roleList.length > 0
                  ? record.roleList[0].roleName
                  : '-'}
              </span>
            </a-tooltip>
          </div>
        ),
      },
      {
        align: 'left',
        title: '今日首次进场时间',
        dataIndex: 'startTime',
        ellipsis: true,
        customRender: text =>
          text ? this.$moment(text).format('HH:mm:ss') : '-',
      },
      {
        align: 'left',
        title: '今日累计在场时长',
        dataIndex: 'totalDuration',
        ellipsis: true,
        customRender: text => (text === 0 || text > 0 ? text + '小时' : '-'),
      },
    ];
    if (!this.hasShare) {
      list.push({
        title: this.$t('enterpriseManagement.camera.tableColumn.operation'),
        key: 'operation',
        ellipsis: true,
        customRender: (_, record) => (
          <span
            style={{
              color: 'var(--font-active)',
              cursor: 'pointer',
            }}
            onClick={() => this.handleDeviceItem(record)}
          >
            详情
          </span>
        ),
      });
    }
    return list;
  }
  @Prop({ type: Object }) form;
  @Prop({ type: Object }) shareParams;
  @Prop({ type: Boolean }) hasShare;
  async getData() {
    this.loading = true;
    try {
      const params = {
        searchDayEnum: this.form.searchDayEnum,
        projectId: this.form.projectIds,
        deviceGroupIds: this.form.groupIdList.toString(),
        current: this.pagination.current,
        size: this.pagination.pageSize,
      };
      const { records, total } = this.hasShare
        ? await shareRealPresenceDetail(params, this.shareParams)
        : await realPresenceDetail(params);
      this.tableData = records;
      this.pagination.total = total;
      // eslint-disable-next-line no-empty
    } catch (error) {
    } finally {
      this.loading = false;
    }
  }
  handleDeviceItem(item) {
    const route = this.$router.resolve({
      path: `/hat/clock?key=2&userId=${item.userId}&date=${this.form.searchDayEnum}`,
    });
    window.open(route.href, '_blank');
  }
  splitFieldForList(list, type) {
    let str = list.reduce((v, a) => v + a[type] + '、', '');
    if (str.slice(-1) === '、') {
      str = str.slice(0, -1);
    }
    return str;
  }
  handlePagination(data) {
    this.pagination = data;
    this.getData();
  }
  mounted() {
    this.getData();
  }
}
</script>
<style lang="less" module>
.avatar {
  height: 0.24rem;
  width: 0.24rem;
  border-radius: 50%;
  object-fit: cover;
  transform: translateY(28%);
  margin-right: 0.1rem;
}
</style>
