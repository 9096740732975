<template>
  <div :class="$style.container">
    <div :class="$style.mask" v-if="showMask"></div>
    <div :class="$style.map" id="map"></div>
  </div>
</template>
<script>
import { Component, Vue, Prop, InjectReactive } from 'vue-property-decorator';
import {
  drawCharts,
  formatToApi,
  funAMap,
  mouseEvent,
} from '@/views/hat/electron-fence/components/util';
import { statusType, messageType, alarmType } from '@/views/hat/enum';
import ManageModel from '@/views/hat/device/manage-model/index.vue';
import { Debounce } from 'lodash-decorators';
import { createModal } from '@triascloud/x-components';
import {
  getDeviceList,
  getGeoFenceZoneList,
} from '@/services/smart-hat/screen';
import {
  getDeviceList as getShareDeviceList,
  getGeoFenceZoneList as getShareGeoFenceZoneList,
} from '@/services/smart-hat/screen-share';
import { checkHasShare } from '@/views/hat/screen/utils';
import { Emitter } from '@/utils';
import Theme from '@/views/hat/screen/config/theme';

/**
 * @name 电子围栏列表
 * @description key为电子围栏Id，value为电子围栏是否加载
 */
const fenceMap = new Map();
let map, AMap, alarmMass, remindMass, noticeMass, normalMass;

const skin = localStorage.getItem('HAT_SCREEN_SKIN');

/** @name 安全帽设备类型 */
export const DEVICE_TYPE = {
  /** @name 警报 */
  ALARM: 'ALARM', // 警报
  /** @name 提醒 */
  REMIND: 'REMIND', // 提醒
  /** @name 通知 */
  NOTICE: 'NOTICE', // 通知
  /** @name 正常 */
  NORMAL: 'NORMAL', // 正常
};

const typeEnum = {
  [DEVICE_TYPE.ALARM]: 0, // 警报
  [DEVICE_TYPE.REMIND]: 1, // 提醒
  [DEVICE_TYPE.NOTICE]: 2, // 通知
  [DEVICE_TYPE.NORMAL]: 3, // 正常
};

@Component({
  components: {},
})
export default class HatScreenMap extends Vue {
  @Prop({ type: Object }) searchParam;
  @InjectReactive('screenSetting') screenSetting;

  /** 记录状态类型的选中状态 */
  typeSelect = {
    alarm: true,
    remind: true,
    notice: true,
    normal: true,
  };
  mounted() {
    // this.initData();
    Emitter.$on('device-type', item => {
      switch (item.type) {
        case DEVICE_TYPE.ALARM:
          this.typeSelect.alarm = item.selected;
          item.selected ? alarmMass.show() : alarmMass.hide();
          break;
        case DEVICE_TYPE.REMIND:
          this.typeSelect.remind = item.selected;
          item.selected ? remindMass.show() : remindMass.hide();
          break;
        case DEVICE_TYPE.NOTICE:
          this.typeSelect.notice = item.selected;
          item.selected ? noticeMass.show() : noticeMass.hide();
          break;
        case DEVICE_TYPE.NORMAL:
          this.typeSelect.normal = item.selected;
          item.selected ? normalMass.show() : normalMass.hide();
          break;
        case 'ONLINE':
          this.isOnline = item.selected;
          this.updatePosition(this.searchParam);
          break;
      }
    });
  }
  // beforeDestroy() {
  //   map && map.destroy();
  // }
  isFirstLoad = true;
  async initData(params) {
    await this.getDevices(params);
    await this.initMap(params);
    // 只在页面第一次加载时执行
    if (this.isFirstLoad) {
      this.loadPosition();
    }
    this.isFirstLoad = false;
  }
  showMask = true;
  zoom = 4.2;
  center = [102.342785, 35.312316];
  async initMap(params) {
    // 筛选【项目】时，清除所有围栏ID
    if (!params.projectIds) {
      fenceMap.clear();
    }
    this.showMask = true;
    AMap = await funAMap();
    map = new AMap.Map('map', {
      zoom: this.zoom,
      pitch: 40, // 地图俯仰角度，有效范围 0 度- 83 度
      viewMode: '3D', // 地图模式
      center: this.center,
      mapStyle: Theme[skin].mapLink,
    });
    alarmMass = this.loadMassivePosition(
      AMap,
      map,
      this.deviceList.filter(x => x.lastMessageType === DEVICE_TYPE.ALARM),
    );
    remindMass = this.loadMassivePosition(
      AMap,
      map,
      this.deviceList.filter(x => x.lastMessageType === DEVICE_TYPE.REMIND),
    );
    noticeMass = this.loadMassivePosition(
      AMap,
      map,
      this.deviceList.filter(x => x.lastMessageType === DEVICE_TYPE.NOTICE),
    );
    normalMass = this.loadMassivePosition(
      AMap,
      map,
      this.deviceList.filter(x => x.lastMessageType === DEVICE_TYPE.NORMAL),
    );
    this.showFence(AMap, map);
    map.on('complete', () => {
      // 解决地图闪屏问题
      setTimeout(() => {
        this.showMask = false;
      }, 500);
    });
    // marker.setMap(map);
  }
  loadMassivePosition(AMap, map, list) {
    const style = [
      {
        url: require('@/assets/images/smart-hat/position-1.png'),
        anchor: new AMap.Pixel(18, 18),
        size: new AMap.Size(36, 36),
      },
      {
        url: require('@/assets/images/smart-hat/position-2.png'),
        anchor: new AMap.Pixel(18, 18),
        size: new AMap.Size(36, 36),
      },
      {
        url: require('@/assets/images/smart-hat/position-3.png'),
        anchor: new AMap.Pixel(18, 18),
        size: new AMap.Size(36, 36),
      },
      {
        url: require('@/assets/images/smart-hat/position-4.png'),
        anchor: new AMap.Pixel(18, 18),
        size: new AMap.Size(36, 36),
      },
    ];
    const mass = new AMap.MassMarks(list, {
      opacity: 0.8,
      zIndex: 111,
      cursor: 'pointer',
      style: style,
    });
    const marker = new AMap.Marker({
      content: ' ',
      map: map,
      offset: new AMap.Pixel(17.5, 20),
    });
    mass.on('mouseover', e => {
      const {
        avatar,
        deviceName,
        status,
        userName,
        lastMessageSubType,
        lastMessageType,
        lastMessageTime,
        lastStatusTime,
        messageNumber,
      } = e.data;
      const str = `
        <span>${alarmType[lastMessageType]}: ${messageNumber || 0}${this.$t(
        'hat.screen.item',
      )}</span><br />
        <span>${this.$t('hat.screen.recently')}: ${
        messageType[lastMessageSubType]
      }(${this.$moment(lastMessageTime).format(
        'YYYY-MM-DD HH:mm:ss',
      )})</span><br />`;
      const content = `
        <div class="infoWindow">
          <div class="info">
             <div class="name">
              ${userName}
             </div>
             <span>${deviceName}</span><br />
             <span>${this.$t(
               'enterpriseManagement.camera.deviceStatus.title',
             )}: ${statusType[status]}</span><br />
             ${lastMessageType === 'NORMAL' ? '' : str}
             <span>${this.$t('hat.screen.updateDevice')}: ${this.$moment(
        lastStatusTime,
      ).format('YYYY-MM-DD HH:mm:ss')}</span><br />
          </div>
          <img src="${
            avatar ? avatar : require('@/assets/img/default-avatar.png')
          }" alt="" />
        </div>`;
      marker.setPosition(e.data.lnglat);
      marker.setContent(content);
      map.add(marker);
    });
    mass.on('mouseout', () => {
      map.remove(marker);
    });
    mass.on('dblclick', e => {
      if (!checkHasShare(this.$route)) {
        this.openManageModal(e.data.deviceId);
      }
    });
    mass.on('click', e => {
      map.setZoomAndCenter(15, e.data.lnglat);
    });
    mass.setMap(map);
    return mass;
  }
  async loadPosition() {
    if (this.screenSetting.scope === 'IP') {
      // 获取本地经纬度
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(position => {
          map.setZoom(16);
          map.setCenter([position.coords.longitude, position.coords.latitude]);
        });
      }
    } else if (this.screenSetting.scope === 'GROUP') {
      const param = {
        groupIdList: this.screenSetting.groupId,
        projectIds: '',
        searchDayEnum: 'THIRTY_DAY',
      };
      const data = !checkHasShare(this.$route)
        ? await getDeviceList(param)
        : await getShareDeviceList(param, {
            shareId: this.$route.params.id,
            password: this.$route.params.password,
          });
      if (data.length && this.screenSetting.groupId) {
        this.zoom = 16;
        map.setZoom(16);
        map.setCenter([data[0].lng, data[0].lat]);
      }
    } else {
      map.setZoom(4.2);
      map.setCenter([102.342785, 35.312316]);
    }
  }
  /**
   * @name 更新地图坐标点
   * @description
   * 在不重新加载地图的前提下，定时更新地图坐标点
   */
  async updatePosition(params) {
    alarmMass.clear();
    remindMass.clear();
    noticeMass.clear();
    normalMass.clear();
    await this.getDevices(params);
    alarmMass = this.loadMassivePosition(
      AMap,
      map,
      this.deviceList.filter(x => x.lastMessageType === DEVICE_TYPE.ALARM),
    );
    remindMass = this.loadMassivePosition(
      AMap,
      map,
      this.deviceList.filter(x => x.lastMessageType === DEVICE_TYPE.REMIND),
    );
    noticeMass = this.loadMassivePosition(
      AMap,
      map,
      this.deviceList.filter(x => x.lastMessageType === DEVICE_TYPE.NOTICE),
    );
    normalMass = this.loadMassivePosition(
      AMap,
      map,
      this.deviceList.filter(x => x.lastMessageType === DEVICE_TYPE.NORMAL),
    );
    // 如果类型状态是未选中，则隐藏对应类型的坐标
    !this.typeSelect.alarm && alarmMass.hide();
    !this.typeSelect.remind && remindMass.hide();
    !this.typeSelect.notice && noticeMass.hide();
    !this.typeSelect.normal && normalMass.hide();
  }
  /**
   * @name 显示电子围栏
   * @description
   * 1、监听Map的zoom[2,20]的变更事件，当为zoom=14，获取可视区域内的marker
   * 2、可视区域通过Map的getBounds获取，并通过bounds构建出一个矩形，用来判断每个marker是否在矩形内
   * 3、通过可视范围内的每个marker的围栏id，获取相关的围栏
   */
  showFence(AMap, map) {
    const change = async e => {
      let zoom = e.target.getZoom();
      if (zoom >= 14) {
        const tmpBounds = map.getBounds();
        const southWest = new AMap.LngLat(
          tmpBounds.southWest.lng,
          tmpBounds.southWest.lat,
        );
        const northEast = new AMap.LngLat(
          tmpBounds.northEast.lng,
          tmpBounds.northEast.lat,
        );
        const bounds = new AMap.Bounds(southWest, northEast);
        const rectangle = new AMap.Rectangle({
          map,
          bounds,
        });
        this.deviceList.forEach(item => {
          const LngLat = new AMap.LngLat(+item.lng, +item.lat);
          if (rectangle.contains(LngLat)) {
            if (item.idxGeofenceId && !fenceMap.has(item.idxGeofenceId)) {
              fenceMap.set(item.idxGeofenceId, false);
            }
          }
        });
        map.remove(rectangle);
        let array = [];
        fenceMap.forEach((value, key, map) => {
          if (!map.get(key)) {
            array.push(key);
            map.set(key, true);
          }
        });
        if (array.length > 0) {
          const param = {
            geofenceIds: array.toString(),
          };
          const data = !checkHasShare(this.$route)
            ? await getGeoFenceZoneList(param)
            : await getShareGeoFenceZoneList(param, {
                shareId: this.$route.params.id,
                password: this.$route.params.password,
              });
          this.zoneArray = formatToApi(data);
          this.chartList = drawCharts(this.zoneArray, AMap, (...args) =>
            mouseEvent.call(null, ...args, map),
          );
          map.add(this.chartList);
        }
      }
    };
    map.on('zoomchange', change);
    // map.off('zoomchange', change);
  }
  isOnline = false;
  deviceList = [];
  async getDevices(params) {
    const param = {
      groupIdList: params.groupIdList.toString(),
      projectIds: params.projectIds.toString(),
      searchDayEnum: params.searchDayEnum,
    };
    const data = !checkHasShare(this.$route)
      ? await getDeviceList(param)
      : await getShareDeviceList(param, {
          shareId: this.$route.params.id,
          password: this.$route.params.password,
        });
    if (data.length && params.groupIdList.length) {
      this.zoom = 16;
      this.center = [data[0].lng, data[0].lat];
    } else {
      this.zoom = 4.2;
      this.center = [102.342785, 35.312316];
    }
    this.deviceList = data.map(item => {
      item.lnglat = [item.lng, item.lat];
      // item.lnglat = [116.258446, 37.686622];
      item.style = typeEnum[item.lastMessageType];
      return item;
    });
    if (this.isOnline) {
      this.deviceList = this.deviceList.filter(
        item => item.status === 'ONLINE',
      );
    }
  }
  chartList = [];
  @Debounce(300)
  async openManageModal(deviceId) {
    const record = {
      deviceId: deviceId,
    };
    createModal(() => <ManageModel record={record} />, {
      width: 1280,
      title: this.$t('hat.deviceManagement.manage'),
      maskClosable: false,
    });
  }
}
</script>
<style lang="less" module>
.container {
  height: 100%;
  width: 100%;
  position: relative;
  .map {
    height: 100%;
    width: 100%;
  }
  .mask {
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    background: var(--mask-background);
    z-index: 99;
  }
}
/* 隐藏高德logo  */
:global .amap-logo {
  display: none !important;
}
/* 隐藏高德版权  */
:global .amap-copyright {
  display: none !important;
}
:global .amap-icon img {
  height: 34px;
}
:global .infoWindow {
  width: 3rem;
  padding: 0.16rem 0.24rem;
  position: relative;
  background: #0f2d61;
  .info {
    .name {
      font-size: 0.24rem;
      width: 1.7rem;
      text-shadow: 0 0 7px #1f69c8, 0 0 10px #7db6ff;
      color: #ffffff;
    }
    & > span {
      display: inline-block;
      font-size: 0.14rem;
      color: #a7c4d7;
      width: 100%;
      // padding: 0.02rem 0.08rem;
      margin-top: 0.16rem;
      // overflow: hidden;
      // text-overflow: ellipsis;
      // white-space: nowrap;
    }
    & > span:first-of-type {
      margin-top: 0.1rem;
    }
  }
  img {
    height: 0.56rem;
    width: 0.56rem;
    position: absolute;
    right: 0.24rem;
    top: 0.08rem;
    border-radius: 50%;
    object-fit: cover;
  }
}
</style>
